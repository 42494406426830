
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/featured-binocs",
      function () {
        return require("private-next-pages/featured-binocs.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/featured-binocs"])
      });
    }
  